<!--/**-->
<!--* 自动生成 vue列表代码【模块名：寄样表】-->
<!--* 生成日期：2024/02/28 15:59:10-->
<!--* 生成路径: src/pages/product_specimen/ProductSpecimenList.vue-->
<!--* 生成人：tyest-->
<!--*/-->
<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div class='table-search'>
          <el-form :model='searchCondition' inline size='mini'>
            <el-form-item label='物流单号'>
              <el-input v-model='searchCondition.nu' placeholder='物流单号' clearable></el-input>
            </el-form-item>
            <el-form-item label='快递编码'>
              <el-input v-model='searchCondition.com' placeholder='快递公司编码,一律用小写字母' clearable></el-input>
            </el-form-item>
            <el-form-item label='快递名称'>
              <el-input v-model='searchCondition.com_label' placeholder='快递公司名称' clearable></el-input>
            </el-form-item>
            <el-form-item label='微信id'>
              <el-input v-model='searchCondition.openid' placeholder='微信用户id' clearable></el-input>
            </el-form-item>
            <el-form-item label='是否退样'>
              <el-input v-model='searchCondition.need_back' placeholder='是否退样' clearable></el-input>
            </el-form-item>
            <el-form-item label='创建时间'>
              <el-date-picker
                v-model='searchCondition.date_range'
                type='daterange'
                align='right'
                unlink-panels
                range-separator='至'
                start-placeholder='开始日期'
                end-placeholder='结束日期'
                value-format='yyyy-MM-dd'
                :picker-options='pickerOptions'>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' @click='handleQuery'>搜索</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!--        操作栏-->
        <el-row class='mb8' :gutter='10'>
          <el-col :span='1.5'>
            <el-button type='primary' icon='el-icon-plus' v-if='hasAuthority("product_specimen_create")' @click='handleAdd'>新增</el-button>
          </el-col>
<!--          <el-col :span='1.5'>-->
<!--            <el-button icon='el-icon-upload' v-if='hasAuthority("product_specimen_import")'>导入</el-button>-->
<!--          </el-col>-->
<!--          <el-col :span='1.5'>-->
<!--            <el-button icon='el-icon-download' v-if='hasAuthority("product_specimen_export")'>导出</el-button>-->
<!--          </el-col>-->
        </el-row>
        <!--    渲染表格-->
        <div>
          <MlPageTable :all-columns='defaultColumns'
                       :table-name='tableName'
                       :data-list='dataList'
                       :limit.sync='pageData.page_size'
                       :page.sync='pageData.current_page'
                       :total.sync='total'
                       :height='height'
                       highlight-current-row
                       :row-class-name='tableRowClassName'
                       @row-click='rowClickEvent'
                       @getList='getList'>
            <el-table-column align='center' width='120' label='操作'>
              <template slot-scope='{row,$index}'>
                <el-button type='text' icon='el-icon-edit' v-if='hasAuthority("product_specimen_edit")' @click='handleEdit(row)'>修改</el-button>
                <el-button type='text' icon='el-icon-delete' v-if='hasAuthority("product_specimen_delete")' @click='handleDelete(row,$index)'>删除</el-button>
              </template>
            </el-table-column>
          </MlPageTable>
        </div>
      </div>

      <!-- 添加或修改对话框 -->
      <el-dialog :title='title' :visible.sync='open' width='800px' append-to-body :close-on-click-modal='false'>
        <el-form ref='form' :model='form' :rules='rules' label-width='100px'>
          <el-form-item label='物流单号' prop='nu'>
            <el-input v-model='form.nu' clearable placeholder='请输入物流单号' />
          </el-form-item>
          <el-form-item label='快递编码' prop='com'>
            <el-input v-model='form.com' clearable placeholder='请输入快递公司编码,一律用小写字母' />
          </el-form-item>
          <el-form-item label='快递名称' prop='com_label'>
            <el-input v-model='form.com_label' clearable placeholder='请输入快递公司名称' />
          </el-form-item>
          <!--          <el-form-item label='微信用户id' prop='openid'>-->
          <!--            <el-input v-model='form.openid' placeholder='请输入微信用户id' clearable/>-->
          <!--          </el-form-item>-->
          <el-form-item label='是否退样' prop='need_back'>
            <el-input v-model='form.need_back' clearable placeholder='请输入是否退样' />
          </el-form-item>
          <el-form-item label='备注' prop='remark'>
            <el-input v-model='form.remark' clearable placeholder='请输入备注' />
          </el-form-item>

        </el-form>
        <div slot='footer' class='dialog-footer'>
          <el-button type='primary' @click='submitForm' :disabled='loadingCommit' :loading='loadingCommit'>确 定
          </el-button>
          <el-button @click='cancel'>取 消</el-button>
        </div>
      </el-dialog>

    </PageHeaderLayout>
  </div>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'ProductSpecimenList',
  components: { PageHeaderLayout, MlPageTable },
  data() {
    return {
      tableName: 'product_specimen',
      loading: false,
      loadingCommit: false,
      dataList: [],
      total: 0,
      height: 800,
      searchCondition: {
        date_range: [],
        nu: null,
        com: null,
        com_label: null,
        openid: null,
        need_back: null,
        remark: null
      },
      pageData: { current_page: 1, page_size: 100 },
      orderBy: { id: 'asc' },
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      defaultColumns: [{ hide: true, prop: 'id', label: '序号', width: 120, sortable: false, fixed: false },
        { hide: true, prop: 'nu', label: '物流单号', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'com', label: '快递公司编码', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'com_label', label: '快递公司名称', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'openid', label: '微信用户id', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'need_back', label: '是否退样', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'remark', label: '备注', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'created_at', label: '创建时间', width: 120, sortable: false, fixed: false },
        { hide: true, prop: 'updated_at', label: '修改时间', width: 120, sortable: false, fixed: false },
        // { hide: true, prop: 'deleted_at', label: '删除时间', width: 120, sortable: false, fixed: false }
        ],
      // 弹出层标题
      title: '添加/修改-寄样表',
      // 是否显示弹出层
      open: false,
      // 表单校验
      rules: {
        nu: [{ required: true, message: '物流单号不能为空', trigger: 'blur' }],
        com: [{ required: true, message: '快递公司编码,一律用小写字母不能为空', trigger: 'blur' }],
        com_label: [{ required: true, message: '快递公司名称不能为空', trigger: 'blur' }],
        openid: [{ required: true, message: '微信用户id不能为空', trigger: 'blur' }],
        need_back: [{ required: true, message: '是否退样不能为空', trigger: 'blur' }],
        remark: [{ required: true, message: '备注不能为空', trigger: 'blur' }]
      },
      // 表单参数
      form: {}
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async rowClickEvent(row, column, event) {
      switch (column.property) {
        case 'nu':
          this.$notify.success('递物流信息')
          // eslint-disable-next-line no-case-declarations
          const param = {}
          param['nu'] = row.nu
          param['com'] = row.com
          //顺丰快递、顺丰速运 需要提供手机号码
          if (['shunfengkuaiyun', 'shunfeng'].indexOf(row.com) > -1) {
            //
            this.$prompt('请输入收件人或寄件人的联系方式', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消'
              // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
              // inputErrorMessage: '邮箱格式不正确'
            }).then(async ({ value }) => {
              this.$message({
                type: 'success',
                message: '你的联系方式是: ' + value
              })
              // 手机号码
              param['phone'] = value
              await this.$api.syncQueryLogisticsInfo(param)
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '取消输入'
              })
              return false
            })
          }else {
            await this.$api.syncQueryLogisticsInfo(param)
          }
          break
      }
      // this.$notify.info(`数据ID：${row.id}`)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (field !== 'date_range' && this.searchCondition[field]) {
          // console.log(Object.prototype.toString.call(this.searchCondition[field]))
          // console.log((this.searchCondition[field]).constructor)
          // console.log(typeof (this.searchCondition[field]))

          condition[field] = this.searchCondition[field]
        }
      })

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }

      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()

    },
    async getList() {
      this.dataList = []
      this.loading = true
      let params = this.handleSearchCondition()
      let { list, pages } = await this.$api.getProductSpecimenList(params)
      this.dataList = [...this.dataList, ...list]
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      this.loading = false
    },
    /** 删除按钮 */
    handleDelete(row, index) {
      if (row.id) {
        //真实删除
        this.$confirm('此操作将删除该行数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delRow(row.id)
          this.dataList.splice(index, 1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.tableData.splice(index, 1)
      }
    },
    delRow(id) {
      this.$api.deleteProductSpecimen(id)
    },
    /** 修改按钮 */
    handleAdd() {
      this.open = true
      this.title = '添加-寄样表'
      // let { info } = await this.$api.getProductSpecimenInfo(row.id)
      this.form = {}
    },
    /** 修改按钮 */
    async handleEdit(row) {
      this.open = true
      this.title = '修改-寄样表'
      // let { info } = await this.$api.getProductSpecimenInfo(row.id)
      this.form = { ...row }
    },

    /** 提交按钮 */
    async submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loadingCommit = true
          this.$api.saveProductSpecimen(this.form).then(id => {
            if (id) {
              if (!this.form.id)
                this.msgSuccess('新增成功')
              else
                this.msgSuccess('修改成功')

              this.form.id = id
              this.open = false
              this.getList()
            } else {
              this.msgError('操作失败')
            }
            setTimeout(() => {
              this.loadingCommit = false
            }, 500)
          })

        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.form = {}
    }
  },
  created() {
    this.handleQuery()
  }
}
</script>

<style scoped>

</style>
